









































































import { Component, Vue } from 'vue-property-decorator';
import { getComponent,getView } from '@/utils/helpers';
import workspaceModule from '@/store/modules/workspaceModule';
import workflowModule from '@/store/modules/workflowModule';

@Component({
  components: {
    AppLayout: () => getComponent('common/AppLayout'),
    WorkspacesNavbar: () => getComponent('common/WorkspacesNavbar'),
	Schedule: () => getView('ScheduleV2'),
	Calendar: () => getView('Calendar'),
	WellTestSchedule: () => getView('WellTestSchedule'),
	WorkspacesCreateNewTableModal: () => getComponent('common/WorkspacesCreateNewTableModal'),
  },
})
export default class Workspaces extends Vue {

  loadingTasqs = false

  addNewTablePopup = false

  workflowAutomationDialog = true

  viewSelected = 'Schedule'

  didSelectView(view) {
	  this.viewSelected = view
  }

//   get activeTable() {
//     return workspaceModule.activeWorkspace;
//   }

  get activeWorkspace() {
    return workspaceModule.activeWorkspace;
  }
  async created() {
    this.loadingTasqs = true;
	  workspaceModule.setActivePage(null)
	  workspaceModule.resetAllData()
	  await workspaceModule.getWorkspaces(null)
	  await workspaceModule.setActiveWorkspace(workspaceModule.activeWorkspaces[0].ID);


	if (this.$route.params.table_id) {
	  this.$router.push({
		name: 'Workspaces',
		params: {
			id: workspaceModule.activeWorkspaces[0].ID,
			table_id: this.$route.params.table_id
		},
	  });
	  workspaceModule.setActiveTable({id: this.$route.params.table_id || ''});
	} else if (workspaceModule.activeTables.length > 1) {
	  this.$router.push({
		name: 'Workspaces',
		params: {
			id: workspaceModule.activeWorkspaces[0].ID,
			table_id: this.$route.params.table_id
		},
	  });
	  workspaceModule.setActiveTable({id: workspaceModule.activeTables[1].ID || ''});
	} else {
		this.$router.push({
		name: 'Workspaces',
		params: {
			id: workspaceModule.activeWorkspaces[0].ID,
		},
	  });
	  workspaceModule.setActiveTable({id:''});	
	}


	// if (this.$route.params.table_id) {
	// 	// @ts-ignore
	// 	await workspaceModule.getWorkspaces(this.$route.params.table_id)
	// } else {
	// 	await workspaceModule.getWorkspaces(null)
	// }
    
	
    // if (this.$route.params.id) {
    //   workspaceModule.setActiveWorkspace(this.$route.params.id);
    // }
    this.loadingTasqs = false;
	
  }

  closeAddNewTablePopup() {
	  this.addNewTablePopup = false
  }

  addNewTable() {
	  this.addNewTablePopup = true
  }




}
